.dashboard__row{
    display: flex;
    justify-content: space-around;
    background-color: white;
    margin: 10px 0;
    flex-direction: column;
    width: 100%;
    overflow: auto;
}
.dashboard >h1{
    height: 35px;
    width: 162px;
    color: #1f2833;
    font-family: "Titling Gothic FB Compressed";
    font-weight: bold;
    /* font-size: 30px; */
    letter-spacing: 1.46px;
    line-height: 35px;
}

@media only screen and (min-width:770px){
    .dashboard__row{
        flex-direction: row;
    }
}