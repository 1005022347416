.row-styles {
  background-color: #454d55;
  color: #fff;
}

/* .header {
  text-align: center;
  font-weight: 500;
  font-size: larger;
  font-weight: 700;
} */

.table-head{
  background-color: #1f2833;
  font-weight: 600;
  color: #fff;
}

.sch-button{
  height: 40px;
  width:auto;
}

/* .input-row {
  font-weight: 500;
  font-size: larger;
  font-weight: 700;
} */

.scheduler {
  height: inherit;
}

.flexbox {
  flex-wrap: wrap;
  overflow-wrap: break-word;
}

.today-status-all{
  background-color: slategrey;
  color: aliceblue;
}

.today-status-yet-to-run{
  background-color: darkgoldenrod;
  color: aliceblue;
}

.today-status-in-progress{
background-color: cornflowerblue;
color: aliceblue;
}

.today-status-success{
  background-color: #4CAF4A;
  color: aliceblue;
}

.today-status-failure{
  background-color: crimson;
  color: aliceblue;
}

.next-schedule{
  background-color: slategrey;
}

.error-scroll-style{
    max-height: 200px;
    overflow-y: scroll;
}

/*Styles for toggle starts*/

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/*Styles for toggle ends*/

.input-field-scheduler {
  display: flex;
  flex-direction: column;
}

.input-field-text{
  align-self: baseline;
}

.input-field-scheduler .input-field-select{
  width: auto;
  margin-left: 0;
  display: inline;
}
.input-field-scheduler .input-field-button{
  margin-left: 0;
  margin-top: 10px;
}

.scheduler-template-table{
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  flex: 0 0 66.6666%;
  max-width: 100%;
}
.scheduler-template-status{
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  flex: 0 0 33.3333%;
  max-width: 100%;
}
.scheduler-template-container{
  flex-direction: column;
}
@media only screen and (min-width:426px){
  .input-field-scheduler {
    flex-direction: row;
  }
  .input-field-text{
    align-self: center;
  }
  .input-field-scheduler .input-field-select{
    width: 20%;
    margin-left: 1rem;
  }
  .input-field-scheduler .input-field-button{
    margin-left: 1rem;
    margin-top: 0;
  }
  .scheduler-template-table{
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 66.6666%;
    max-width: 66.666%;
  }
  .scheduler-template-status{
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }
  .scheduler-template-container{
    flex-direction: row;
  }
}