.coupon-card {
    margin: 20px 10px;
    padding: 10px;
    height: fit-content;
    background-color: #f8f9fa;
    overflow-x: auto;
}

.coupon-card:hover {
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.5);
}

.card-info {
    height: auto;
    text-align: right;
    padding: 5px;
    font-size: .75rem;
    font-weight: bold;
}

.coupon-preview-id {
    float: left;
}

.bg-green {
    background-color: #0c800c;
    border-radius: 20px;
    padding: 0px 5px;
    color: white;
}

.bg-red {
    background-color: #a72323;
    border-radius: 20px;
    padding: 0px 5px;
    color: white;
}