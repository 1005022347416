.coupon-table{
	color: #1F2833;
	font-family: "TitlingGothicFB Narrow";
	font-size: 14px;
	font-weight: 300;
	letter-spacing: 0.48px;
	line-height: 19px;
}

.table-container{
background: white;
width: auto;
overflow-x: scroll;
}

.actionItems {
	display: flex;
	justify-content: space-around;
}