.scheduleEvent{
    /* max-width: 70vw; */
    /* height: 100vh; */
    min-height: 100vh;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
}
.scheduleEvent__row{
    display: flex;
    justify-content: space-between;
    margin: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid lightgray;
    flex-direction: column;
}
.scheduleEvent__info{
    display: flex;
    align-content: center;
    
}
.scheduleEvent__header{
    font-family: "Titling Gothic FB Compressed Standard";
    margin-bottom: 0;    
}
.scheduleEvent__info > p {
 margin-bottom: 0;
 margin-top: 5px;
 font-family: "TitlingGothicFB Narrow";
}
.scheduleEvent__right{
    align-content: center;
    display: grid;
    margin-top: 10px;
}
.couponEvent__search{
    width: 220px;
    padding: 5px;
    border: 1px solid lightgrey;
    border-radius: 5px 0 0 5px;
    margin-left: 0;
}
.scheduleEvent__row .couponEvent__searchIcon{
    /* background-color: #3f51b5; */
    border-radius: 0 5px 5px 0;
}
.tabs-responsive{
    flex-direction: column;
}
@media only screen and (min-width:426px){
    .scheduleEvent__row{
        flex-direction: row;
    }
    .scheduleEvent__right{
        margin-top: 0;
    }
    .couponEvent__search{
        margin-left: 10px;
    }
    .tabs-responsive{
        flex-direction: row;
    }
}