
@font-face {
  font-family: "TitlingGothicFB Narrow";
  font-style: normal;
  font-weight: bold;
  src: url('./fonts/TitlingGothicFBNar-Reg.otf') format('opentype');
}

@font-face {
  font-family: "Titling Gothic FB Compressed Standard";
  font-style: normal;
  font-weight: bold;
  src: url('./fonts/Titling Gothic FB Compressed Standard.otf') format('opentype');
}

.login-header {
  height: 106px;
  width: 100%;
  background-color: #1f2833;
  padding-right: 0;
}

#header-title {
  height: 35px;
  width: 275px;
  color: #ffffff;
  font-family: "TitlingGothicFB Narrow";
  font-size: 18px;
  letter-spacing: .88px;
  line-height: 35px;
  padding-left: 31px;
  white-space: nowrap;
  padding-top: 26px;
}

#header-subtitle {
  height: 35px;
  width: 150px;
  color: #ffffff;
  font-family: "TitlingGothicFB Narrow";
  font-size: 14px;
  letter-spacing: .68px;
  line-height: 35px;
  padding-left: 31px;
  padding-top: 15px;
  white-space: nowrap;
}