.scheduleModal__headerSection >h3{
    font-family: "Titling Gothic FB Compressed Standard";
}
.scheduleModal__headerSection{
    margin-bottom: 15px;
    border-bottom: 1px solid blue;
}
.scheduleModal__fields_select{
    width: 180px;
    margin-right: 25px;
}
.scheduleModal__row{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
.scheduleModal__fields__date{
    display: flex;
    flex-direction: column;
    margin-right: 25px;
}
.scheduleModal__fields__date >input{
    height: 40px;
    border: none;
    color: #808080;
    border-radius: 3px;
    padding-left: 10px;
    width: 180px;
}
.scheduleModal__fields__date >label ,.scheduleModal__fields_select >label{
    font-family: "TitlingGothicFB Narrow";
    /* font-family: "Titling Gothic FB Compressed Standard"; */
    font-weight: 600;
    margin-bottom: 0;
}
.scheduleModal__row{
    margin-bottom: 15px;
}
.scheduleModal__buttons{
    display: flex;
    justify-content: flex-end;
    padding-right: 25px;
    flex: auto ;
}
.scheduleModal__validator__text >p{
    margin-top: 1em;
    margin-bottom: 0;
    font-family: "TitlingGothicFB Narrow";
    color: #c6084b;
}

.scheduleModal{
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    border: none;
    width: auto;
    height: fit-content;
    background-color: #e9e9e9;
    position: absolute;
    padding: 20px;
}
@media only screen and (min-width:426px){
    .scheduleModal{
        width: 450px;
    }
    .scheduleModal__row{
        flex-direction: row;
    }
}