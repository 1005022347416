.dealers_cardSection {
    display: grid;
    grid-template-columns: 300px ;
    justify-content: center;
    grid-gap: 15px 25px;
    margin-top: 15px;
}

.dealerCard {
    background: white;
    margin: 10px;
    width: 300px;
    min-height: 150px;
    padding: 5px;
    box-shadow: 2px 2px 5px grey;
}

.dealerCard__row {
    display: flex;
}

.dealerCard__id {
    flex: 1;
    color: grey;
    font-weight: bold;
    margin: 0;
}

.dealerCard__name {
    margin: 0;
    font-weight: bold;
}

.dealerCard__address {
    color: grey;
    margin: 0;
    font-size: .9rem;
}

.participant_tag {
    color: green;
    border-radius: 5px;
    font-weight: bold;
    font-size: .8rem;
    margin-right: 5px;
    display: inline-block;
}

.opt_for {
    font-size: .8rem;
    font-weight: bold;
    margin: 0;
}

.dealerCard__phone2 {
    margin: 0;
    color: grey;
    font-size: .8rem;
    font-weight: bold;
}

.dealers_showMore {
    display: flex;
    justify-content: center;
}

.dealers_showMore {
    padding-top: 5px;
    padding-bottom: 5px;
    margin-bottom: 10px;
}

.dealer-input-field{
    width: 70%;
}
.dealer-preview-header-top{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    background-color: #1f2833;
}
.dealer-preview-find-comp{
    position: relative;
    width: 100%;
     padding-left: 20px;
}

@media only screen and (min-width:426px){
    .dealers_cardSection {
        grid-template-columns: 300px 300px ;
    }
    .dealer-preview-header-top{
        flex-direction: row;
        height: 55px;
    }
    .dealer-preview-find-comp{
        flex: 0 0 50%; 
        max-width: 50%;
        position: relative;
        width: 100%;
        padding-right: 15px;
         padding-left: 15px;
    }
}
@media only screen and (min-width:770px){
    .dealers_cardSection {
        grid-template-columns: 300px 300px 300px 300px;
    }
}