@font-face {
    font-family: "Titling Gothic FB Compressed";
    font-style: normal;
    font-weight: bold;
    src: url('./fonts/Titling Gothic FB Compressed Standard.otf') format('opentype');
}

@font-face {
    font-family: "TitlingGothicFB Narrow";
    font-style: normal;
    src: url('./fonts/Titling Gothic FB Narrow Light.otf') format('opentype');
}

body {
    background-color: #eef1f4;
    border: none;
}

.form-style {
    padding-top: 20px;
    padding-left: 10px;
}

.create-coupon-block {
    padding-bottom: 10px;
}

.create-coupon {
    height: 35px;
    width: 100%;
    color: #1f2833;
    font-family: "Titling Gothic FB Compressed";
    font-weight: bold;
    font-size: 30px;
    letter-spacing: 1.46px;
    line-height: 35px;
}

.field-header {
    height: 24px;
    width: 56px;
    color: #0c0c0d;
    font-family: "TitlingGothicFB Narrow";
    font-weight: bold;
    font-size: 12px;
    letter-spacing: .41px;
    line-height: 21px;
    margin-bottom: 10px;
}

.rectangle-copy {
    height: 32px;
    width: 100%;
    background-color: #ffffff;
    border: none;
    padding-left: 20px;
    padding-right: 30px;
    appearance: none;
    /* !important used for overriding all other customisations */
    background: url("http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png") white no-repeat calc(100% - 10px);
    /* Better placement regardless of input width*/
}

.inputfield {
    height: 32px;
    width: 100%;
    background-color: #ffffff;
    border: none;
}

.name {
    height: 24px;
    width: 34px;
    color: #0c0c0d;
    font-family: "TitlingGothicFB Narrow";
    font-weight: bold;
    font-size: 12px;
    letter-spacing: .41px;
    line-height: 21px;
}

.price-header {
    color: #0c0c0d;
    font-family: "TitlingGothicFB Narrow";
    font-weight: bold;
    font-size: 12px;
    letter-spacing: .41px;
    line-height: 15px;
    display:inline-flex;
}

.form-column-style {
    flex-grow: 0;
    padding-left: 2px;
    padding-right: 20px;
    margin-top: 10px;
}

.form-column-check {
    flex-grow: 0;
    padding-left: 2px;
    padding-right: 20px;
}

.price-required-copy-2 {
    height: 30px;
    width: 100%;
    color: #0c0c0d;
    font-family: "TitlingGothicFB Narrow";
    font-weight: bold;
    font-size: 12px;
    letter-spacing: .41px;
    line-height: 15px;
    display: block;
}

.check-box-style {
    height: 25px;
    width: 25px;
    margin-bottom: 0;
    bottom: 0;
}

.checkbox-styling {
    flex-grow: 0;
    padding-left: 2px;
    padding-right: 18px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.post-price-field {
    height: 32px;
    width: 100%;
    background-color: #ffffff;
    border: none;
}

.form-column {
    flex-grow: 0;
    padding-left: 2px;
    padding-right: 18px;
    margin-top: 20px;
}

.form-row .text-area {
    width: 100%;
    background-color: #ffffff;
    border: none;
    vertical-align: middle;
}

.form-row {
    padding-top: 5px;
    padding-bottom: 5px;
    margin-left: 0 !important;
    display: flex;
    flex-direction: column;
}

.coupon-id {
    height: 32px;
    width: 100%;
    background-color: #ffffff;
}

.register-now {
    height: 50px;
    width: 100%;
    color: #ffffff;
    font-family: "Titling Gothic FB Compressed";
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 19px;
    text-align: center;
    background-color: #0068b3;
    border: none;
}

.cancel-button {
    height: 50px;
    width: 100%;
    color: #0068b3;
    font-family: "Titling Gothic FB Compressed";
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 19px;
    text-align: center;
    background-color: #ffffff;
    border-color: #0068b3;
    border-width: thin;
}

.input-sample {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}

/* .form-column label {
    position: relative;
    cursor: pointer;
  } */

.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: flex;
    height: 40px;
}

.upload-btn-wrapper .btn {
    height: 19px;
    color: #000000;
    font-family: "Titling Gothic FB Compressed";
    text-decoration: underline;
    letter-spacing: 1.4px;
    line-height: 19px;
    font-weight: 800;
}

.upload-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}

.cancel-button {
    height: 19px;
    width: 100%;
    color: #000000;
    background-color: #eef1f4;
    font-family: "Titling Gothic FB Compressed";
    font-size: 16px;
    letter-spacing: 1.4px;
    line-height: 19px;
    text-decoration: underline;
        display: inline-block;
        padding-top: 15px;
}

.check-box-style.active-or-not{
    height: 25px;
    width: 25px;
    margin-bottom: 0;
    bottom: 0;
    margin-top: 6px;
}

.price-field-header{
    height: 24px;
    color: #0c0c0d;
    font-family: "TitlingGothicFB Narrow";
    font-weight: bold;
    font-size: 1.1rem;
    letter-spacing: .41px;
  }
  .price-field-header-wrapper{
      display: flex;
      margin-top: 20px;
  }
  .sort-order{
    height: 32px;
    width: 100%;
    background-color: #ffffff;
    border: none;
    padding-left: 10px;
}
.expire-date{
    height: 32px;
    width: 100%;
    background-color: #ffffff;
    border: none;
    padding-left: 10px;
}
.mg-tp-m12{
    margin-top: -12px;
  }

.edit-coupon-container{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
.right-section{
    padding:5px;
    margin:10px;
    box-shadow:0px 8px 16px 0px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
}
  @media only screen and (min-width:426px){
    .create-coupon {
        width: 162px;
    }
    
    .rectangle-copy {
        width: auto;
    }
    
    .inputfield {
        width: 360px;
    }
    .post-price-field {
        width: 100px;
    }    
    .form-row .text-area {
        width: 600px;
    }
    
    .form-row {
        flex-direction: row;
    }
    
    .coupon-id {
        width: 200px;
    }
    
    .register-now {
        width: 210px;
    }
    
    .cancel-button {
        width: 210px;
    } 
    .cancel-button {
        width: 45px;
    }
      .sort-order{
        width: 80px;
    }
    .expire-date{
        width: auto;
    }
    .edit-coupon-container{
        justify-content: space-between;
        flex-direction: column;
    }
    .right-section{
        flex-direction: row;
        justify-content: space-around;
    }
  }

  @media only screen and (min-width:770px){
    .form-row .text-area {
        width: 870px;
    }
    .edit-coupon-container{
        display: flex;
        justify-content: space-between;
        flex-direction: row;
    }
    .right-section{
        flex-direction: column;
        justify-content: flex-start;
    }
  }