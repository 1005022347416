.dropdown-list {
    position: absolute;
    border: black 1px;
    background-color: #f9f9f9;
    text-align: left;
    /* margin-top: -10px; */
    left: 10%;
    z-index: 1000;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}
.dd-wrapper{
    margin:0;
    height:20px;
    width:20px;
    display:inline-block;
}
.dd-wrapper img{
  height:15px;
  vertical-align: text-bottom;
}
/* Drop down List Styling */
.dropdown-list button{
    border: none;
    background-color: inherit;
    color: blue;
    margin: 0  15px 0 0;

}

.dropdown-list button:hover{
    color: #800cbcd9;
    text-decoration: underline;

}
.dropdown-list_fix_top {
    border-bottom:1px solid #dee2e6;
    padding: 10px;
    align-content: center;
    display: flex;
    justify-content: left;
    background-color: #eef1f4 ;
}
.dropdown-list_fix_bottom {
    border-top: 1px solid #dee2e6;
    padding: 10px;
    display: flex;
    justify-content: left;
    background-color: #eef1f4 ;
}
.dropdown-list .form-check{
    padding: 5px 0 0 30px;
    background-color:#f9f9f9;
}

.dropdown-list .form-check:hover{
    background-color: rgb(188, 212, 236);
}
.dropdown-list-content{
    overflow-y: scroll;
    margin-top:10px;
    max-height: 200px;
    width:200px;
    white-space: break-spaces;

}

/* Scroll Bar Styling */
  .dropdown-list-content::-webkit-scrollbar-track
{
    background-color:inherit;
}

.dropdown-list-content::-webkit-scrollbar
{
    width: 10px;
    height:10px;
    background-color:inherit;
}

.dropdown-list-content::-webkit-scrollbar-thumb
{
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #555;
}

@media only screen and (min-width:426px){
    .dropdown-list {
        left: unset;
    }
}
