.date-filter {
    float: right;
}



.date-filterText {
    border: none;
    padding: 5px;
    font-weight: bold;
}

.date-filter-popup-container {
    z-index: 2000;
    position: absolute;
    margin-top: 30px;
    background-color: #eef1f4;
    min-height: 150px;
    min-width: auto;
    padding: 20px 10px;
    color: black;
    font-family: inherit;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.5);
}

.date-filter-setbutton-container {
    text-align: center;
    margin-top: 10px;
}

.date-filter-popup-container label {
    margin-top: 10px;

}

.date-filter-setbutton-container button {
    background-color: #0068B3;
    width: 100px;
    color: #fff;
    padding: 5px;
    font-weight: bold;
}