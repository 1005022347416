.coupon-count-container {
    margin-left: 20px;
}

.coupon-count-text {
    color: #1f2833;
    font-family: "Titling Gothic FB Compressed";
    font-weight: bold;
    font-size: 25px;
    letter-spacing: 1.46px;
    margin: 0;
}
.content-space-between{
    justify-content: space-between;
}
.reset-filter-previewCoupon-button{
    background: transparent;
    border: none;
}
.reset-filter-previewCoupon-button:disabled{
    background: transparent;
    color: grey;
    border: none;
}