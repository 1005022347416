.login-page{
    display: flex;
    min-height: 100vh;
    max-height: fit-content;
    background-color: whitesmoke;
}
.login-container {
    margin: 0;
    min-height: 396px;
    max-height: fit-content;
    width: 580px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    box-shadow: 5px 5px 10px 5px lightgrey;
}
.greeting-section{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 20px;
    /* background-color: indigo; */
    /* color: white; */
    border-radius: 10px 10px 0 0;
}
.login-section{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #5c88da;
    color: white;
    padding: 20px;
    width: 100%;
}

.text-input-username{
    border-radius: 5px;
    background: white;
}
.text-input-password{
    border-radius: 5px;
    background: white;
}
.login-header-title{
    margin-bottom: 20px;
}

.login-form-section{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.login-form-inline{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.input-block{
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}
.input-block .login-label{
    margin-bottom: 0;
}
.heading-container{
    margin-bottom: 15px;
}
.heading-container-tile{
    margin-bottom: 45px;
    text-align: center;
}
.brand-logo{
    text-align: center;
}
.brand-logo img{
    width:120px;
}
.login-button-container .login-submit{
    border-radius: 20px;
}
.invalid-attempt-container{
    color: red;
}

@media only screen and (min-width:426px){
    .login-container {
        margin: auto;
        flex-direction: row;
    }
    .greeting-section{
        padding: 0 20px;
        border-radius: 10px 0 0 10px;
    }
    .login-section{
        padding: 0 20px;
        border-radius: 0 10px 10px 0;
        width:50%;
    }
}