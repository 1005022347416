.category-table{
    position: static;
    width: 100%;
    left: 0;
    background-color: white;
}

.catgory-table-header-top{
    /* height: 55px; */
    background-color: #1f2833;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.create-category-button-style{
    height: 50px;
    width: 100%;
    float: right;
    border: none;
    background-color: #0068b3;
}
.create-category-cta-style{
    height: 19px;
    width: 118px;
    color: #ffffff;
    font-family: "Titling Gothic FB Compressed";
    font-size: 16px;
    letter-spacing: 1.4px;
    line-height: 19px;
}
.create-category-label-style{
    height: 35px;
    width: 183px;
    color: #1f2833;
    font-family: "Titling Gothic FB Compressed";
    font-size: 30px;
    letter-spacing: 1.46px;
    line-height: 35px;
}
.create-category-form-column{
    flex-grow: 0;
    padding-left: 0;
    padding-right: 30px;
}
.create-category-field-header{
    height: 24px;
    width: 34px;
    color: #475666;
    font-family: "TitlingGothicFB Narrow";
    font-size: 12px;
    letter-spacing: .41px;
    line-height: 21px;
}
.create-category-order-field-style{
    height: 50px;
    width: 100%;
    background-color: #ffffff;
    border: none;
    margin-top: 5px;
    padding-left: 20px;
}
.create-category-form-row{
    padding-top: 20px;
    display: flex;
    padding-bottom: 10px;
    margin-left: 0;
    /* padding-left: 30px; */
    flex-direction: column;
}
.create-category-name-field-style{
    height: 50px;
    width: 100%;
    background-color: #ffffff;
    border: none;
    margin-top: 5px;
    padding-left: 30px;
}
.create-category-button {
    height: 50px;
    width: 100%;
    color: #ffffff;
    font-family: "Titling Gothic FB Compressed";
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 19px;
    text-align: center;
    background-color: #0068b3;
    border: none;
    margin-bottom: 10px;
}
.create-category-cancel-button{
    height: 50px;
    width: 100%;
    color: #0068b3;
    font-family: "Titling Gothic FB Compressed";
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 19px;
    text-align: center;
    background-color: #ffffff;
    border-color: #0068b3;
    border-width: thin;
}
.modal-overlay-size{
    width: 587px;
    height: 280px;
}
.category-count{
    font-family: "TitlingGothicFB Narrow";
    color: white;
    font-size: 15px;
}
.category-count{
    padding-top: 15px;
    padding-left: 15px;
}
.category-table-container{
    overflow-x: auto;
}
.category-table-layout{
    table-layout: auto;
}
.category-find-comp{
     position: relative;
    width: 100%;
     padding-left: 20px;
}
.category-modal{
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    border: none;
    background-color: #DADFE6;
    width: auto;
    height: auto;
    position: absolute;
    padding: 20px;
    overflow-y: auto;
}
@media only screen and (min-width:426px){
    .category-table{
        position: absolute;
        width: 70%;
        left: 20%;
        background-color: white;
    }
    .catgory-table-header-top{
        height: 60px;
        flex-direction: row;
    }
    .category-find-comp{
        flex: 0 0 50%; 
        max-width: 50%;
        position: relative;
        width: 100%;
        padding-right: 15px;
         padding-left: 15px;
    }
    .create-category-button-style{
        height: inherit;
        width: 157px;
    }
    .create-category-cancel-button{
        width: 210px;
    }
    .create-category-form-row{
        flex-direction: row;
    }
    .create-category-name-field-style{
        width: 396px;
    }
    .create-category-order-field-style{
        width: 100px;
    }
    .create-category-button {
        width: 210px;
        margin-bottom: 0;
    }
}
@media only screen and (min-width:770px){
    .category-table{
        position: absolute;
        width: 50%;
        left: 25%;
        background-color: white;
    }
    .catgory-table-header-top{
        height: 55px;
        flex-direction: row;
    }
}