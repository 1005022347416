.sign-out {
    color: #ffffff;
    font-family: "TitlingGothicFB Narrow";
    font-size: 12px;
    letter-spacing: .58px;
    line-height: 35px;
}

.sign-out-container {
    height: 35px;
    width: 51px;
    /* margin-left: 5px; */
    padding: 0px 10px;
}
