.special-codes-container {
    background-color: #2e3c4d;
    color: #ffffff;
    box-sizing: border-box;
    padding: 0.5em;
    margin-bottom: 1rem;
    width: 100%;
}

.special-codes-header{
    font-weight: 500;
    font-size: 1.7rem;
    letter-spacing: 0.09rem;
    line-height: 3rem;
    font-family: "Titling Gothic FB Compressed";
}

.special-codes{
    overflow-y: scroll;
    height: 200px;
    line-height: 1.5em;
    letter-spacing: 0.05rem;
    font-size: 0.88rem;
}

.special-codes div{
    display: inline-block;
}

.special-code-key{
    width: 10em;  
}

.special-code-separator{
    width: 2em;
    text-align: center;
}

.special-code-value{
    width: 7rem;
}

/* Scroll Bar Styling */
.special-codes::-webkit-scrollbar-track
{
    background-color:rgba(0,0,0,0.3);
}

.special-codes::-webkit-scrollbar
{
    width: 10px;
    height:10px;
    background-color:inherit;
}

.special-codes::-webkit-scrollbar-corner{
    background-color:rgba(0,0,0,0.3);
}

.special-codes::-webkit-scrollbar-thumb
{
    border-radius: 10px;
    box-shadow: inset 0 0 6p black;
    -webkit-box-shadow: inset 0 0 6px black;
    background-color: whitesmoke
}

@media only screen and (min-width:426px){
    .special-codes-container {
        width: 360px;
    }
    .special-codes{
        height: 400px;
    }
}
@media only screen and (min-width:770px){
    .special-codes{
        height: 200px;
    }
}