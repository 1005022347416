.error-main-div{
    padding-left: 0px !important;
    padding-right: 0px !important;
}
.avatar-sec{
    margin-top: 20px;
    text-align: center;
}
.profile-info{
    margin-top: 20px;
    text-align: center;
}
.profile-page{
    margin-top: 50px;
    font-family: "TitlingGothicFB Narrow";
}
.profile-info p{
    text-align: left;
}
.profile-info span{
    width: 100px;
    display: inline-block;
}
.profile-page-tab .custom-tab{
    flex-direction: column;
    overflow-x: inherit;
}
.profile-page-tab .tab-content{
    padding: 30px 0px 30px 0px;
    width: 100%;
}

.profile-page-tab .custom-tab .nav-link{
    border: 1px solid #1f2833;
    border-radius: 0px;
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
}
.profile-page-tab .custom-tab .nav-link:hover{
    border: 1px solid #1f2833;
}
.profile-page-tab .custom-tab .nav-link.active{
    background: #1f2833;
    color: #fff;
    position: relative;
    border: 1px solid #1f2833;
}
.session-log-box::after{
    content: '';
    position: absolute;
    height: 100px;
    width: 1px;
    right: 10px;
    background: #0000001a;
}
.form-control.custom-form-control:focus{
    box-shadow: none;
    border-color: #ced4da;
}

.btn.custom:focus{
    box-shadow: none;
}
.btn.btn-primary.custom:focus{
    box-shadow: none;
}
.btn-primary.custom:not(:disabled):not(.disabled):active:focus{
    box-shadow: none;
}
.alert.alert1{
    margin-bottom: 0px;
}
@media only screen and (min-width: 768px){
    .profile-info{
        text-align: left;
    }
}
@media only screen and (min-width: 600px){
    .profile-page{
        padding-left: 0px !important;
    }
    .profile-page-tab .tab-content{
        padding: 0px 0px 0px 100px;
    }
    .profile-page-tab .custom-tab{
        width: 200px;
        height: calc(100vh - 130px);
    }
    .profile-page-tab{
        display: flex;
    }
    
    .profile-page-tab .custom-tab .nav-link.active{
        position: relative;
        font-size: 16px;
    }
    .profile-page-tab .custom-tab .nav-link.active::after{
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-bottom: 70px solid transparent;
        border-left: 85px solid #1f2833;
        border-top: 70px solid transparent;
        font-size: 0;
        line-height: 0;
        right: -85px;
    }
}
.submit-btn{
    height: auto;
    text-decoration: none;
}