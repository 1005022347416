

.restore__headerSection{
    margin-bottom: 15px;
    border-bottom: 1px solid blue;
    display:flex;
    justify-content: space-between;
    padding-top: 20px;
    position: sticky;
    top: 0;
    background-color: white;
    
}
.restore__headerSection >h3{
    font-family: "Titling Gothic FB Compressed Standard";
}
.restore_operationSection{
    display: flex;
    justify-content: flex-end;
    position: sticky;
    bottom: 0;
    background-color:white;
    padding: 5px 0 5px 0;
}
.restore_operationSection button{
    margin-right: 20px;
}
.errorMessage{
    flex: 1;
    color: red;
    /* font-family: "Titling Gothic FB Compressed Standard"; */

}
.restore-review-modal{
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    border: none;
    height: fit-content;
    background-color: white;
    width: 100%;
    max-height: 90%;
    padding: 0 10px 0 10px;
    min-height: 400px;
    position: absolute;
    overflow-y: auto;

}
@media only screen and (min-width:426px){
    .restore-review-modal{
        width: 70%;
        padding: 0 20px 0 20px;
    }
}