.input-group-container {
    background-color: #1f2833;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.input-group-fields-contianer{
    display: flex;
    flex-direction: row;
    /* align-items: flex-start; */
}
.input-field-coupon-style{
    /* width: 50%; */
    color: white;
    background-color: #1f2833;
    border: none;
    margin: 10px;
}

.input-field-bottom-border-coupon-style{
    border-bottom: 1px solid white;
}

.input-field-coupon-style :focus{
    outline: none;
}

.input-field-font-coupon-style{
        color: #ffffff;
        font-family: "TitlingGothicFB Narrow";
        font-size: 12px;
        letter-spacing: .58px;
}

.input-group-container p{
    margin: 10px;
}

.reset-filter-button{
    background: transparent;
    color: white;
    border: none;
}
.reset-filter-button:disabled{
    background: transparent;
    color: grey;
    border: none;
}

@media only screen and (min-width:426px){
    .input-group-container {
        background-color: #1f2833;
        flex-direction: row;
        justify-content: space-evenly;
    }
    .input-group-fields-contianer{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        flex: 1;
    }
    .input-field-coupon-style{
        /* width: 50%; */
        color: white;
        background-color: #1f2833;
        border: none;
    }
    
    .input-field-bottom-border-coupon-style{
        border-bottom: 1px solid white;
    }
    
    .input-field-coupon-style :focus{
        outline: none;
    }
    
    .input-field-font-coupon-style{
            color: #ffffff;
            font-family: "TitlingGothicFB Narrow";
            font-size: 12px;
            letter-spacing: .58px;
    }
    
    .input-group-container p{
        margin: 10px;
    }
    
    .reset-filter-button{
        background: transparent;
        color: white;
        border: none;
    }
    .reset-filter-button:disabled{
        background: transparent;
        color: grey;
        border: none;
    }
}