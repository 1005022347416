.dealer-table{
    box-shadow: -4px 7px 37px -10px rgba(10,10,10,1);
    background-color:white;
}
.container-fluid-dealers{
    width: 100%;
}
.dealer-center-align{
    justify-content: center;
    
}
.dealer-table-component{
    overflow-x: auto;
}
.modal-overlay-size{
    width: 587px;
    height: 280px;
}
.input-field-coupon-style{
    color: white;
    background-color: #1f2833;
    border: none;
}
.dealer-input-field{
    width: 50%;
}
.input-field-bottom-border-coupon-style{
    border-bottom: 1px solid white;
}
.input-field-coupon-style :focus{
    outline: none;
}
.find-label{
    color: white;
}
.dealer-table-header-top{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    background-color: #1f2833;
}
.dealer-align-center{
    position: relative;
    width: 100%;
}
.dealer-find-comp{
    position: relative;
    width: 100%;
     padding-left: 20px;
}

@media only screen and (min-width:426px){
    .container-fluid-dealers{
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
    }
    .dealer-find-comp{
        flex: 0 0 50%; 
        max-width: 50%;
        position: relative;
        width: 100%;
        padding-right: 15px;
         padding-left: 15px;
    }
    .dealer-align-center{
        padding-bottom: .5rem;
        padding-top: .5rem;
        flex: 0 0 66.666667%; 
        max-width: 66.666667%;
        position: relative;
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
    }    
    .dealer-table-header-top{
        flex-direction: row;
        height: 55px;
    }


}
@media only screen and (max-width:768px){
    .container-fluid-dealers{
        width: 100%;
        padding-right: 0;
        padding-left: 0;
        margin-right: 0;
        margin-left: 0;
    }
    .dealer-align-center{
        padding-bottom: 0;
        padding-top: 0;
        flex: 1; 
        max-width: 100%;
        position: relative;
        width: 100%;
        padding-right: 0;
        padding-left: 0;
    }
}