a.nav-item.nav-link.active  {
    color: #0068b3;
    font-family: "TitlingGothicFB Narrow";
    font-size: 14px;
    font-weight: 500;
    letter-spacing: .68px;
    border-bottom: 2px solid #5d88da;
    white-space: nowrap;
}


a.nav-item.nav-link {
    color: #475666;
    font-family: "TitlingGothicFB Narrow";
    font-size: 14px;
    font-weight: 500;
    letter-spacing: .68px;
    background-color: #eef1f4;
    white-space: nowrap;
}
.navbar-container .nav {
    display: flex;
    flex-wrap: nowrap;
    padding-left: 0;
    list-style: none;
    overflow-x: scroll;
}
@media only screen and (min-width:426px){
    .navbar-container .nav {
        overflow-x: unset;
    }
}