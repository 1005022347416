.bulkUpdate__link{
    background: transparent;
    color: white;
    border: none;
    white-space: nowrap;
    width: 100%;
}

.bulkUpdate__headerSection >h3{
    font-family: "Titling Gothic FB Compressed Standard";
}
.bulkUpdate__headerSection{
    margin-bottom: 15px;
    border-bottom: 1px solid blue;
}
.bulkUpdate__fields_select{
    margin-right: 25px;
    width: 100%;
}
.bulkUpdate__row{
    display: flex;
    justify-content: space-between;
}
.bulkUpdate__fields__date{
    display: flex;
    flex-direction: column;
    margin-right: 25px;
}
.bulkUpdate__fields__date >input{
    height: 40px;
    border: none;
    color: #808080;
    border-radius: 3px;
    padding-left: 10px;
    width: 180px;
}
.bulkUpdate__fields__date >label ,.bulkUpdate__fields_select >label , .bulkUpdate__selectall > label, .bulkUpdate__fields__select > label{
    font-family: "TitlingGothicFB Narrow";
    /* font-family: "Titling Gothic FB Compressed Standard"; */
    font-weight: 600;
    margin-bottom: 0;
}
.bulkupdate_radiogroup > label{
    font-family: "TitlingGothicFB Narrow";
    /* font-family: "Titling Gothic FB Compressed Standard"; */
    font-weight: 600;
    margin-bottom: 0;
    margin-right: 20px;
    margin-left: 5px;
}
.bulkUpdate__row{
    margin-bottom: 15px;
}
.bulkUpdate__buttons{
    display: flex;
    justify-content: flex-end;
    padding-right: 25px;
    flex: auto ;
}
.bulkUpdate__validator__text >p{
    margin-top: 1em;
    margin-bottom: 0;
    font-family: "TitlingGothicFB Narrow";
    color: #c6084b;
}
.bulkUpdate__selectall{
    display: flex;
}
.bulkUpdate__selectall input {
    width: 18px;
    height: 18px;
    margin-right: 5px;
}
.bulkUpdate__fields__select > select{
    height: 40px;
    width: auto;
    background-color: #ffffff;
    border: none;
    padding-left: 20px;
    padding-right: 30px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) white no-repeat calc(100% - 10px);
}
.bulkUpdate_modal{
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    border: none;
    width: auto;
    height: fit-content;
    background-color: #e9e9e9;
    position: absolute;
    padding: 20px;
}
@media only screen and (min-width:426px){
    .bulkUpdate_modal{
        width: 560px;
    }
}