
.persistent-header {
  background-color: #1f2833;
  display: flex;
  flex-direction: column;
}

.persistent-header-title {
  height: 35px;
  width: 275px;
  color: #ffffff;
  font-family: "TitlingGothicFB Narrow";
  font-size: 18px;
  letter-spacing: .88px;
  line-height: 35px;
  padding-left: 20px;
  white-space: nowrap;
  font-weight: bold;
}

.persistent-header-subtitle {
  height: 35px;
  width: 150px;
  color: #ffffff;
  font-family: "TitlingGothicFB Narrow";
  font-size: 14px;
  letter-spacing: .68px;
  padding-left: 20px;
  white-space: nowrap;
  font-weight: bold;
}

 #greet {
  color: #ffffff;
  font-family: "TitlingGothicFB Narrow";
  font-size: 14px;
  letter-spacing: .68px;
  line-height: 35px;
  white-space: nowrap;
}

#persistent-header-partition {
  height: 19px;
  width: 1px;
  color: #ffffff;
}

#header-greeting-container{
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  margin: -40px 0px 5px;
}

@media only screen and (min-width:426px){
  .persistent-header {
    flex-direction: row;
  }
  .persistent-header-container {
    flex: 1;
  }  
   #greet {
    flex: 0 0 auto;
  }
  #header-greeting-container{
    margin: 15px 0px 0px;
  }
}
.persistent-header-container a:hover{
  text-decoration: none;
}
#header-greeting-container svg{
  color: #ffffff;
}
#greet a{
  color: #ffffff;
}
.home-button, #greet {
  padding: 0px 10px;
}
.sb-avatar__text:hover{
  background-color: #000;
  width: 100px;
  height: 100px;
  color: #ffffff;
}