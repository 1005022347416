.table-wrapper-scroll-y{
    /* position: relative; */
    /* height: 80vh; */
    overflow: auto;
    display: block; 
    width: 100%;
    margin-top: -15px;
}
.table-wrapper-scroll-y thead th {
    background-color: #2a2a65;
    color: white;
    text-align: center;
    padding: 10px;
  }
.table-wrapper-scroll-y tbody td{
    vertical-align: middle;
    padding: 6px;
    text-align: center;
}
