.preview-coupon-container-inside-updatecoupon {
    height: auto;
    background-color: #ffffff;
    position: sticky;
    top: 0;
}

.preview-coupon-container {
    top: 304px;
    left: 78.82%;
    right: 1.39%;
    height: 100%;
    background-color: #ffffff;
}

.preview-coupon-header {
    display: flex;
    justify-content: flex-end;
    height: 55px;
    background-color: #1f2833;
    text-align: center;
}

.preview-coupon-header h1 {
    height: 15px;
    width: 94px;
    color: #ffffff;
    font-family: "TitlingGothicFB Narrow";
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    padding-top: 19px;
    margin-right: 11px;
}

.preview-coupon-header select {
    height: 35px;
    margin-top: 7px;
    margin-right: 20px;
    color: #ffffff;
    background-color: #1f2833;
    font-family: "TitlingGothicFB Narrow";
    font-size: 12px;
    letter-spacing: .58px;
    line-height: 35px;
}

.preview-coupon-container #id-coupon {
    height: 11px;
    width: 28px;
    color: #1f2833;
    font-family: "TitlingGothicFB Narrow";
    font-size: 11px;
    font-weight: 500;
    letter-spacing: .38px;
    line-height: 13px;
    margin-top: 19px;
    margin-left: 20px;
    margin-bottom: 46px;
}

.create-coupon-button{
        height: 50px;
        width: 210px;
        color: #ffffff;
        font-family: "Titling Gothic FB Compressed";
        font-size: 16px;
        letter-spacing: 1px;
        line-height: 19px;
        text-align: center;
        background-color: #0068b3 ;

}