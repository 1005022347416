@font-face {
  font-family: "Titling Gothic FB Compressed";
  font-style: normal;
  font-weight: bold;
  src: url('./fonts/Titling Gothic FB Compressed Standard.otf') format('opentype');
}

@font-face {
  font-family: "TitlingGothicFB Narrow";
  font-style: normal;
  src: url('./fonts/Titling Gothic FB Narrow Light.otf') format('opentype');
}
body{
    background-color: #eef1f4;
    border: none;
}
.form-style{
    padding-top: 20px;
    padding-left: 10px;
    background-color: #eef1f4;
    width: 100%;
}

.create-coupon-block{
    padding-bottom: 10px;
}
.create-coupon {
    height: 35px;
    width: 162px;
    color: #1f2833;
    font-family: "Titling Gothic FB Compressed";
    font-weight: bold;
    font-size: 30px;
    letter-spacing: 1.46px;
    line-height: 35px;
}
.field-header {
    height: 24px;
    width: 56px;
    color: #0c0c0d;
    font-family: "TitlingGothicFB Narrow";
    font-weight: bold;
    font-size: 12px;
    letter-spacing: .41px;
    line-height: 21px;
    margin-bottom: 10px;
}
.rectangle-copy {
    /* Some browsers will not display the caret when using calc, so we put the fallback first */
    /* background: url("http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png") white no-repeat 98.5% ; !important used for overriding all other customisations */
    background: url("http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png") white no-repeat calc(100% - 10px); /* Better placement regardless of input width*/
    height: 32px;
    width: 100%;
    background-color: #ffffff;
    border: none;
    padding-right: 30px;
    padding-left: 20px;
}
.inputfield {
    height: 32px;
    width: 100%;
    background-color: #ffffff;
    border: none;
}
.name {
    height: 24px;
    width: 34px;
    color: #0c0c0d;
    font-family: "TitlingGothicFB Narrow";
    font-weight: bold;
    font-size: 12px;
    letter-spacing: .41px;
    line-height: 21px;
}

.hide {
    display:none;

}

.price-header {
        /* height: 30px;
        width: 59px; */
        color: #0c0c0d;
        font-family: "TitlingGothicFB Narrow";
        font-weight: bold;
        font-size: 12px;
        letter-spacing: .41px;
        line-height: 15px;
        display: inline-flex;
        margin-top: 20px;
}
.form-column-style{
    flex-grow: 0;
    padding-left: 2px;
    padding-right: 28px;
        margin-top: 10px;
}
.form-column-check{
    flex-grow: 0;
    padding-left: 2px;
    padding-right: 28px;
        margin-top: 2px;
}
.price-required-copy-2 {
    height: 30px;
    width: 59px;
    color: #0c0c0d;
    font-family: "TitlingGothicFB Narrow";
    font-weight: bold;
    font-size: 12px;
    letter-spacing: .41px;
    line-height: 15px;
    display: block;
}
.check-box-style {
    height: 25px;
    width: 25px;
    margin-bottom: 0;
    bottom: 0;
}
.checkbox-styling{
    flex-grow: 0;
    padding-left: 2px;
    padding-right: 18px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
.post-price-field {
    height: 32px;
    width: 100%;
    background-color: #ffffff;
    border: none;
}
.form-column{
    flex-grow: 0;
    padding-left: 2px;
    padding-right: 18px;


}

.text-area {
    width: 100%;
    background-color: #ffffff;
    border: none;
}
.form-row{

    padding-top: 5px;
    padding-bottom: 5px;
    margin-left: 0;
    display: flex;
    flex-direction: column;
}
.sort-order{
    height: 32px;
    width: 100%;
    background-color: #ffffff;
    border: none;
    padding-left: 10px;
}
.expire-date{
    height: 32px;
    width: 100%;
    background-color: #ffffff;
    border: none;
    padding-left: 10px;
}
.register-now {
    height: 50px;
    width: 100%;
    color: #ffffff;
    font-family: "Titling Gothic FB Compressed";
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 19px;
    text-align: center;
    background-color: #0068b3;
    border: none;
}
.sign-in-register {
    height: 50px;
    width: 100%;
    color: #0068b3;
    font-family: "Titling Gothic FB Compressed";
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 19px;
    text-align: center;
    background-color: #ffffff;
    border-color: #0068b3;
    border-width: thin;
}


.input-sample {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }
  .upload-btn-wrapper{
      position: relative;
      overflow: hidden;
      display: flex;
      height: 30px;

  }
  .btn{
        height: 20px;
        color: #000000;
        font-family: "Titling Gothic FB Compressed";
        font-size: 12px;
        text-decoration: underline;
        letter-spacing: 1.4px;
        line-height: 19px;
        font-weight: 800;
  }
  .upload-btn-wrapper input[type=file]{
      font-size: 100px;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
  }
  .coupon-id-style{
    height: 32px;
    width: 100%;
    background-color: #ffffff;
    border: none;
    padding-left: 10px;
  }
  .add-icon-enable{
    color:#0068B3;
    cursor: pointer;
  }
  .add-icon-disable{
    color:#b8c2c7;
    pointer-events: none;
  }
  .remove-icon-enable{
    color:#0068B3;
    cursor: pointer;
  }
  .remove-icon-disable{
    color:#b8c2c7;
    pointer-events: none;
  }
  .price-field-header{
    height: 24px;
    color: #0c0c0d;
    font-family: "TitlingGothicFB Narrow";
    font-weight: bold;
    font-size: 1.1rem;
    letter-spacing: .41px;
  }
  .price-field-header-wrapper{
      display: flex;
      margin-top: 20px;
  }
  .mg-tp-m12{
    margin-top: -12px;
  }
  .programs-box{
      display: block;
      line-height: 2;
      cursor: pointer;
  }

  .create-coupon-modal{
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    border: none;
    height: fit-content;
    background-color: white;
    max-height: 90%;
    min-height: 400px;
    position:absolute;
    padding: 20px;
    overflow-y: auto;
    overflow-x: auto;
    width: 100%;
  }

  @media only screen and (min-width:426px){
    .create-coupon {
        width: 162px;
    }
    .rectangle-copy {
        width: auto;
    }
    .inputfield {
        width: 360px;
    }
    .post-price-field {
        width: 120px;
    }    
    .text-area {
        width: 600px;
    }
    .form-row{
        flex-direction: row;
    }
    .sort-order{
        width: 80px;
    }
    .expire-date{
        width: auto;
    }
    .register-now {
        width: 210px;
    }
    .sign-in-register {
        width: 210px;
    }
      .coupon-id-style{
        width: 80px;
      }
  }

  @media only screen and (min-width:770px){
    .create-coupon-modal{
        width: auto;
      }
      .text-area {
        width: 870px;
    }
  }
  .show-div{
      display: block;
  }
  .hide-div{
      display: none;
  }