.coupon-preview-wrapper {
    margin: 10px 0;
    padding: 10px 0;
}

.coupon-preview-wrapper .create-preview-label {
    height: 35px;
    width: 162px;
    color: #1f2833;
    font-family: "Titling Gothic FB Compressed";
    font-weight: bold;
    font-size: 30px;
    letter-spacing: 1.46px;
    line-height: 35px;
}

.coupon-list-container {
    width: auto;
    display: flex;
    flex-wrap: wrap;
}