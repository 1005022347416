.compareButton_section{
    margin-bottom: 5px;
}
.changelog__headerSection >h3{
    font-family: "Titling Gothic FB Compressed Standard";
}
.changelog__headerSection{
    margin-bottom: 15px;
    border-bottom: 1px solid blue;
    display:flex;
    justify-content: space-between;
}

.changeLogTable_container{
    overflow: auto;
    max-height: 280px;
    min-height : 280px;
    widows: 55%;
}
.changeLogTable_container > .changeLogTable{
    border-collapse: separate;
}


.comaprisonSection_container{
    width: 100%;
    margin-left: 10px;
}

.comparisonSection_responseSection{
    min-height: 280px;
    max-height: 280px;
    overflow: auto;
}

.comparison_header{
    position: sticky;
    top: 0;
    background-color: white;
    padding: 5px 0;
}

.field_dataHeader{
    font-size: smaller;
    margin-bottom: 0;
    padding-left: 10px;
}

.field_header{
    color: gray;
    text-transform: capitalize;
}
.fieldData{
    font-size: smaller;
    padding-left: 20px;
    margin-bottom: 5px;
}
.actual{
    color:red;
}
.modified{
 color: green;
}
.changelog__headerSection > h3> span{
    font-size: smaller;
}
.changeLog__restorebutton{
    border: none;
    color: blue;
    text-decoration: underline;
    background: none;
}
.changelog-modal{
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    border: none;
    height: fit-content;
    background-color: white;
    width: 90%;
    max-height: 90%;
    min-height: 400px;
    position: absolute;
    padding: 20px;
    overflow-y: auto;
}
.changelog-table-container{
    display: flex;
    justify-content: space-between ;
    flex-direction: column;
}
@media only screen and (min-width:426px){
    .comaprisonSection_container {
        width: 45%;
    }
    .changelog-table-container{
        flex-direction: row;
    }
}