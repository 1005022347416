.pagination{
    justify-content: center;
}

.pagination-container {
    font-family: "TitlingGothicFB Narrow";
    padding: 10px 0;
    margin-bottom: 10px;
}

.page-link-active-anchor-container {
    height: 11px;
    width: 5px;
    color: #ffffff !important;
    font-family: "TitlingGothicFB Narrow";
    font-size: 11px;
    letter-spacing: .38px;
    line-height: 13px;
    background-color: #0068b3 !important;
}

.page-link-active-li-container {
    height: 20px;
    width: 20px;
    margin: 0 10px 0 10px;
    background-color: #0068B3 !important;
}

.page-link-anchor-container {
    height: 11px;
    width: 20px;
    color: #475666;
    font-family: "TitlingGothicFB Narrow";
    font-size: 11px;
    letter-spacing: .38px;
    line-height: 13px;
    background-color: #d1e9ff;
}

.page-link-li-container {
    margin: 0 5px 0 5px;
    height: 20px;
    width: 20px;
    background-color: #d1e9ff;
    text-align: center;
    vertical-align: middle;
}

.page-anchor-prev {
    height: 11px;
    width: 31px ;
    color: #475666 ;
    font-size: 11px ;
    font-weight: 500 ;
    line-height: 13px ;
    background-color: white;
    text-decoration: underline;
}

.page-anchor-next {
    height: 11px;
    width: 31px ;
    color: #475666;
    font-size: 11px ;
    font-weight: 500 ;
    line-height: 13px;
    background-color: white;
    text-decoration: underline;
}

.page-li-prev {
    height: 11px;
    width: 31px;
    background-color: white;
}

.page-li-next {
    height: 11px;
    width: 31px;
    background-color: white;

}